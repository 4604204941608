<template>
    <v-row align="center" class="row--xs">
        <v-col>
            <v-text-field v-model="number1" v-bind="$attrs" @input="formatNumber1" placeholder="주민등록번호 앞자리" />
        </v-col>
        <v-col>
            <v-text-field v-model="number2" v-bind="$attrs" @input="formatNumber2" placeholder="주민등록번호 뒷자리" />
        </v-col>
    </v-row>
</template>

<script>
export default {
    props: {
        value: { type: String, default: null },
    },
    data: () => ({
        number1: null,
        number2: null,
    }),
    computed: {
        number() {
            let { number1, number2 } = this;
            return `${number1 || ""}-${number2 || ""}`;
        },
    },
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        sync() {
            [this.number1, this.number2] = (this.value || "-").split("-").map((item) => item || null);
        },
        emit() {
            this.$emit("input", this.number);
        },
        formatNumber1(string) {
            string = string.replaceAll(/\D/g, "").slice(0, 6);
            this.$nextTick(() => {
                this.number1 = string;
                this.emit();
            });
        },
        formatNumber2(string) {
            string = string.replaceAll(/\D/g, "").slice(0, 7);
            this.$nextTick(() => {
                this.number2 = string;
                this.emit();
            });
        },
    },
};
</script>

<style></style>
