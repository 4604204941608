var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-autocomplete', _vm._b({
    on: {
      "click": function ($event) {
        return _vm.$emit('click');
      }
    },
    model: {
      value: _vm.code,
      callback: function ($$v) {
        _vm.code = $$v;
      },
      expression: "code"
    }
  }, 'v-autocomplete', Object.assign({}, _vm.$attrs, {
    items: _vm.items,
    itemText: _vm.itemText,
    itemValue: 'code'
  }), false));

}
var staticRenderFns = []

export { render, staticRenderFns }