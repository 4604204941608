var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-tab-item', _vm._b({}, 'v-tab-item', _vm.BANK_VERIFICATION_FORM_TABS.VERIFY, false), [_c('div', {
    staticClass: "txt txt--xs txt--dark font-weight-medium text-center pt-12px pt-md-0"
  }, [_vm._v(" 해당 계좌번호로 1원이 입금되었습니다."), _c('br'), _vm._v(" 입금 시 표기된 6자리 숫자를 입력해 주세요. ")]), _c('div', {
    staticClass: "pt-12px mx-auto",
    staticStyle: {
      "max-width": "328px"
    }
  }, [_c('v-otp-input', _vm._b({
    attrs: {
      "length": "6",
      "type": "password",
      "hide-details": ""
    },
    model: {
      value: _vm.form.otp,
      callback: function ($$v) {
        _vm.$set(_vm.form, "otp", $$v);
      },
      expression: "form.otp"
    }
  }, 'v-otp-input', {
    loading: _vm.loading
  }, false))], 1), _c('div', {
    staticClass: "btn-wrap"
  }, [_c('v-row', {
    staticClass: "row--sm"
  }, [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-btn', _vm._b({
    staticClass: "v-size--xx-large",
    attrs: {
      "block": "",
      "outlined": "",
      "color": "grey"
    },
    on: {
      "click": _vm.prev
    }
  }, 'v-btn', {
    loading: _vm.loading
  }, false), [_vm._v("이전")])], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-btn', _vm._b({
    staticClass: "v-size--xx-large",
    attrs: {
      "block": "",
      "color": "primary"
    },
    on: {
      "click": _vm.next
    }
  }, 'v-btn', {
    loading: _vm.loading
  }, false), [_vm._v("확인")])], 1)], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }