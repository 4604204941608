<template>
    <v-tab-item v-bind="BANK_VERIFICATION_FORM_TABS.INPUT">
        <tit-wrap-x-small title="계좌정보">
            <span class="font-size-12 red--text">*본인 명의의 통장으로만 입금 받으실 수 있습니다.</span>
        </tit-wrap-x-small>

        <v-row class="row--xs">
            <v-col cols="12">
                <autocomplete-bank v-model="form.bank.code" v-bind="{ ...attrs_input, loading }" placeholder="은행사 선택" @input="emit" />
            </v-col>
            <v-col cols="12">
                <v-text-field v-model="form.bank.accountNumber" v-bind="{ ...attrs_input, loading }" placeholder="계좌번호를 입력하세요." @input="emit" />
            </v-col>
            <v-col cols="12">
                <v-text-field v-model="form.bank.accountHolder" v-bind="{ ...attrs_input, loading }" placeholder="예금주명을 입력하세요." @input="emit" />
            </v-col>
        </v-row>

        <div class="btn-wrap">
            <v-row class="row--sm">
                <v-col cols="6">
                    <v-btn block outlined color="grey" class="v-size--xx-large" v-bind="{ loading }" @click="$emit('close')">취소</v-btn>
                </v-col>
                <v-col cols="6">
                    <v-btn block color="primary" class="v-size--xx-large" v-bind="{ loading }" @click="next">{{ isCreate ? "계좌번호 변경" : "계속하기" }}</v-btn>
                </v-col>
            </v-row>
        </div>
    </v-tab-item>
</template>

<script>
import api from "@/api";
import { attrs_input, BANK_VERIFICATION_FORM_TABS, initBankHoldVerification } from "@/assets/variables";

import TitWrapXSmall from "@/components/client/dumb/tit-wrap-x-small.vue";
import AutocompleteBank from "@/components/dumb/autocomplete-bank.vue";

export default {
    components: {
        TitWrapXSmall,
        AutocompleteBank,
    },
    props: {
        value: { type: Object, default: initBankHoldVerification },
    },
    data: () => ({
        form: initBankHoldVerification(),

        loading: false,

        attrs_input,
        BANK_VERIFICATION_FORM_TABS,
    }),
    computed: {
        isCreate() {
            return this.value?._id;
        },
    },
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.form = initBankHoldVerification(this.value);
        },
        emit() {
            const { ...form } = this.form;
            delete form._id;
            delete form.isSuccess;
            delete form.isOutDated;
            delete form.attemptCount;
            this.$emit("input", initBankHoldVerification(form));
        },
        async next() {
            if (this.loading) return;
            else this.loading = true;

            try {
                const { verification } = await api.v1.promoter.bank.holdVerifications.post(this.form);

                this.$emit("input", initBankHoldVerification(verification));
                this.$emit("setTab", BANK_VERIFICATION_FORM_TABS.VERIFY.value);
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style></style>
