<template>
    <v-tab-item v-bind="BANK_VERIFICATION_FORM_TABS.SUCCESS">
        <div class="txt txt--xs txt--dark font-weight-medium text-center py-20px py-md-0">
            계좌인증이 완료되었습니다.<br />
            인증된 계좌로 보너스 출금이 가능합니다.
        </div>

        <div class="btn-wrap">
            <v-row class="row--sm">
                <v-col :cols="isOnlyVerification ? 12 : 6">
                    <v-btn block outlined color="primary" class="v-size--xx-large" @click="$emit('close')">확인</v-btn>
                </v-col>
                <v-col cols="6" v-if="!isOnlyVerification">
                    <v-btn block color="primary" class="v-size--xx-large" to="/promoter/bonuses-withdraw">출금하러 가기</v-btn>
                </v-col>
            </v-row>
        </div>
    </v-tab-item>
</template>

<script>
import { BANK_VERIFICATION_FORM_TABS } from "@/assets/variables";

export default {
    props: {
        isOnlyVerification: { type: Boolean, default: false },
    },
    data: () => ({
        BANK_VERIFICATION_FORM_TABS,
    }),
};
</script>

<style></style>
