<template>
    <u-dialog v-model="shows" title="계좌 설정" persistent width="400">
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>
        <v-tabs-items v-model="tab">
            <!-- 계좌정보 입력 -->
            <tab-input v-model="verification" v-on="{ close, setTab }" />

            <!-- 인증번호 입력 -->
            <tab-verify v-model="verification" v-on="{ init, setTab }" @input="emit" />

            <!-- 계좌변경 완료 -->
            <tab-success v-bind="{ isOnlyVerification }" v-on="{ close }" />
        </v-tabs-items>
    </u-dialog>
</template>

<script>
import { BANK_VERIFICATION_FORM_TABS, initBank, initBankHoldVerification } from "@/assets/variables";

import UDialog from "@/components/client/dumb/u-dialog.vue";
import TabInput from "./tab-input.vue";
import TabVerify from "./tab-verify.vue";
import TabSuccess from "./tab-success.vue";

export default {
    components: {
        UDialog,
        TabInput,
        TabVerify,
        TabSuccess,
    },
    props: {
        value: { type: Object, default: initBank },
        isOnlyVerification: { type: Boolean, default: false },
    },
    data: () => ({
        verification: initBankHoldVerification(),

        shows: false,
        tab: BANK_VERIFICATION_FORM_TABS.INPUT.value,
    }),
    mounted() {
        this.init();
    },
    watch: {
        shows() {
            if (this.shows) this.init();
        },
        value() {
            this.sync();
        },
    },
    methods: {
        init() {
            this.tab = BANK_VERIFICATION_FORM_TABS.INPUT.value;
            this.sync();
        },
        sync() {
            this.verification = initBankHoldVerification({ bank: this.value });
        },
        emit() {
            const { bank } = this.verification;
            this.$emit("input", { ...bank });
        },
        close() {
            this.shows = false;
        },
        setTab(tab) {
            this.tab = tab;
        },
    },
};
</script>

<style></style>
