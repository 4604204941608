<template>
    <v-row align="center" :class="{ 'row--xs': true, hasVerification }">
        <v-col cols="12">
            <v-text-field v-model="form.accountHolder" placeholder="예금주명을 입력하세요." v-bind="{ ...$attrs, readonly }" v-on="{ click }" @input="emit" />
        </v-col>
        <v-col cols="12" sm="auto">
            <autocomplete-bank v-model="form.code" placeholder="은행사 선택" class="w-100 w-md-200px" v-bind="{ ...$attrs, readonly }" v-on="{ click }" @input="emit" />
        </v-col>
        <v-col cols="12" sm="">
            <v-row class="row--xs">
                <v-col cols="12" sm="">
                    <v-text-field v-model="form.accountNumber" v-bind="{ ...$attrs, readonly }" @input="emit" v-on="{ click }" placeholder="계좌번호를 입력하세요." />
                </v-col>
                <v-col cols="12" sm="auto" v-if="hasVerification">
                    <bank-verification-form v-model="form" ref="bank-verification-form" isOnlyVerification @input="emit">
                        <template #activator="{ attrs, on }">
                            <v-btn color="grey" class="w-100 w-sm-80px" v-bind="{ ...attrs }" v-on="on">계좌확인</v-btn>
                        </template>
                    </bank-verification-form>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
import AutocompleteBank from "@/components/dumb/autocomplete-bank.vue";
import BankVerificationForm from "@/components/client/promoter/bank/verification-form/bank-verification-form.vue";

export default {
    components: {
        AutocompleteBank,
        BankVerificationForm,
    },
    props: {
        value: { type: Object, default: () => ({}) }, // bank
        hasVerification: { type: Boolean, default: false },
    },
    data: () => ({
        loading: false,
        form: {
            code: null,
            accountNumber: null,
            accountHolder: null,
        },
    }),
    computed: {
        readonly() {
            return this.hasVerification;
        },
    },
    mounted() {
        this.sync();
    },
    methods: {
        sync() {
            this.form.code = this.value?.code || null;
            this.form.accountNumber = this.value?.accountNumber || null;
            this.form.accountHolder = this.value?.accountHolder || null;
        },
        emit() {
            const { code, accountNumber, accountHolder } = this.form;
            this.$emit("input", { ...this.value, code, accountNumber, accountHolder });
        },
        click() {
            if (this.hasVerification) {
                this.$refs["bank-verification-form"].$data.shows = true;
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.hasVerification ::v-deep input {
    cursor: pointer;
}
</style>
