<template>
    <v-autocomplete v-model="code" v-bind="{ ...$attrs, items, itemText, itemValue: 'code' }" @click="$emit('click')" />
</template>

<script>
import banks from "@/plugins/shop-banks.json";

export default {
    props: {
        value: { type: [String, Array], default: null },
    },
    data: () => ({
        code: null,
        items: [],
    }),
    computed: {
        item() {
            return this.items.find(({ _id }) => _id == this.code);
        },
    },
    mounted() {
        this.sync();
        this.init();
    },
    watch: {
        value() {
            this.sync();
        },
        code(code) {
            this.$emit("input", code);
        },
    },
    methods: {
        sync() {
            this.code = this.value;
        },
        async init() {
            this.items = banks;
        },
        itemText({ code, name }) {
            return `[${code}] ${name}`;
        },
    },
};
</script>

<style></style>
