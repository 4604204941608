<template>
    <v-tab-item v-bind="BANK_VERIFICATION_FORM_TABS.VERIFY">
        <div class="txt txt--xs txt--dark font-weight-medium text-center pt-12px pt-md-0">
            해당 계좌번호로 1원이 입금되었습니다.<br />
            입금 시 표기된 6자리 숫자를 입력해 주세요.
        </div>
        <div class="pt-12px mx-auto" style="max-width:328px;">
            <v-otp-input v-model="form.otp" length="6" type="password" hide-details v-bind="{ loading }" />
        </div>

        <div class="btn-wrap">
            <v-row class="row--sm">
                <v-col cols="6">
                    <v-btn block outlined color="grey" class="v-size--xx-large" v-bind="{ loading }" @click="prev">이전</v-btn>
                </v-col>
                <v-col cols="6">
                    <v-btn block color="primary" class="v-size--xx-large" v-bind="{ loading }" @click="next">확인</v-btn>
                </v-col>
            </v-row>
        </div>
    </v-tab-item>
</template>

<script>
import { attrs_input, BANK_VERIFICATION_FORM_TABS, initBankHoldVerification } from "@/assets/variables";
import api from "@/api";

export default {
    props: {
        value: { type: Object, default: initBankHoldVerification },
    },
    data: () => ({
        form: initBankHoldVerification(),

        loading: false,

        attrs_input,
        BANK_VERIFICATION_FORM_TABS,
    }),
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.form = initBankHoldVerification(this.value);
        },
        async prev() {
            if (this.loading) return;
            else this.loading = true;

            try {
                this.$emit("setTab", BANK_VERIFICATION_FORM_TABS.INPUT.value);
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.loading = false;
            }
        },
        async next() {
            if (this.loading) return;
            else this.loading = true;

            try {
                var { verification } = await api.v1.promoter.bank.holdVerifications.putVerify(this.form);

                this.$emit("setTab", BANK_VERIFICATION_FORM_TABS.SUCCESS.value);
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.loading = false;
                if (verification?.isOutdated) this.$emit("init");
                this.$emit("input", initBankHoldVerification(verification));
            }
        },
    },
};
</script>

<style></style>
